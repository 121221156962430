import Link from "next/link";
import { usePathname } from "next/navigation";

import { Logo } from "@/ui/assets/Logo";
import { applicationSettings } from "@/constants";
import {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList
} from "@/ui/shadcn/NavigationMenu";
import { cn } from "@/ui/utils";
import { Button } from "@/ui/shadcn";
import { useToggle } from "@/lib/hooks/useToggle";
import { useAnalytics } from "@/hooks/analytics/useAnalytics";

import { MobileMenuHome } from "./MobileMenuHome";
import { ITEMS_LANDING_PAGE } from "./Navbar.constants";
const { LEGACY_WEBSITE_URL } = applicationSettings;

export const NavBarHome = () => {
  const [open, toggle] = useToggle(false);
  const pathname = usePathname();
  const { trackRegisterCta } = useAnalytics({ defaultPageName: "home" });

  const scrollToElement = (id?: string) => {
    if (!id) return;
    const element = document.getElementById(id);
    const offset = 80; // Define the offset value
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return (
    <>
      <div className="flex items-center">
        <Link href={LEGACY_WEBSITE_URL} aria-label="Glo Guides">
          <div className="flex flex-row gap-2">
            <Logo variant="glo" className="text-[var(--color-primary-glo-black)]" />
          </div>
        </Link>
      </div>
      <div className="flex flex-row items-center max-lg:hidden">
        <NavigationMenu className="hidden self-center sm:inline-flex">
          <NavigationMenuList className="flex md:gap-12 xl:gap-[100px]">
            {ITEMS_LANDING_PAGE.map(
              (item) =>
                item.type === "option" && (
                  <NavigationMenuItem key={item.label}>
                    <Link href={item.href ?? "/"} passHref legacyBehavior>
                      <NavigationMenuLink
                        active={pathname?.endsWith(item.href ?? "")}
                        className={cn(
                          navigationMenuTriggerStyle(),
                          "!border-transparent text-xl leading-[26px] hover:border-transparent hover:text-black/50 dark:hover:text-white/50"
                        )}
                        onClick={(e) => {
                          if (item.anchorId) {
                            e.preventDefault();
                            scrollToElement(item.anchorId);
                          }
                        }}
                      >
                        {item.label}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )
            )}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <div className="flex items-center gap-4">
        <Link
          href={`${LEGACY_WEBSITE_URL}/register`}
          onClick={() => trackRegisterCta()}
          onAuxClick={(e) => {
            if (e.button === 2) return; // Skip right-click
            trackRegisterCta();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <Button className="whitespace-nowrap !px-8">
            Try free<span className="max-sm:hidden"> for 7 days</span>
          </Button>
        </Link>
        <MobileMenuHome open={open} toggle={toggle} scrollToElement={scrollToElement} />
      </div>
    </>
  );
};
