"use client";
import React, { createContext, useContext, useMemo, useState } from "react";

import { User } from "@/types";

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (loggedIn: boolean) => void;
  userInfo: User;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: React.FC<{ children: React.ReactNode; user: User }> = ({ user, children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const value = useMemo(() => ({ isAuthenticated, setIsAuthenticated, userInfo: user }), [isAuthenticated, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
